import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';
import { AppComponent } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModalComponent } from 'src/app/alert/modal.component';

@Component({
  selector: 'app-organization-and-others',
  templateUrl: './organization-and-others.component.html',
  styleUrls: ['./organization-and-others.component.css']
})
export class OrganizationAndOthersComponent implements OnInit {

  
  organizationdata:any = [];
  code_no: any;
  name: any;
  phone: any;
  advocatemodal:any = [];
  current_username: string;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = true;

  constructor(
      public rest:LawyerinfoService, 
      private route: ActivatedRoute, 
      private router: Router,
      private SimpleModalService: SimpleModalService,
      public app:AppComponent
  ) { }

  ngOnInit() {
    this.current_username = this.app.getUserName();
   this.getorganizationalllist(); 
  }

  getorganizationalllist() {
    this.isLoadingImage = true
    let postData = {
      code_no: this.code_no,
      name: this.name,
      phone: this.phone,
    }
    console.log(postData);

    this.rest
        .getorganizationalllist(postData)
        .subscribe(
          organizationdata => {
           // console.log(courtwisedata);
              this.organizationdata = organizationdata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false;
            }
        );
  }
  cleanOrganizationalllist() {
  this.isLoadingImage  = true
this.code_no = ''
this.name = ''
     this.phone = ''
     this.organizationdata = []
this.getorganizationalllist()
  
  }

  showAlert(l_id) {
    let postData = {
      l_id: l_id,
    }

    this.rest
        .showOrgAlert(postData)
        .subscribe(
          advocatemodal => {
            //console.log(advocatemodal[0].lawyer_name);
                this.advocatemodal = advocatemodal;
                this.isLoading = false;
                if(this.current_username == 'sadmin' || this.current_username == '105')
                {
                  this.SimpleModalService.addModal(ModalComponent, {title: 'ORGANIZATION INFORMATION', img: advocatemodal[0].lawyer_id, message: 'Code No : ' + advocatemodal[0].lawyer_id + "\n" + 'Name : ' + advocatemodal[0].lawyer_name + "\n\r" + 'Address : ' + advocatemodal[0].address + "\n\r" + 'Phone No : ' + advocatemodal[0].phone  });
                }
                else
                {
                  this.SimpleModalService.addModal(ModalComponent, {title: 'ORGANIZATION INFORMATION', img: advocatemodal[0].lawyer_id, message: 'Code No : ' + advocatemodal[0].lawyer_id + "\n" + 'Name : ' + advocatemodal[0].lawyer_name + "\n\r" + 'Address : ' + advocatemodal[0].address  });
                }
                
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

}
