import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

//const endpoint = 'http://192.168.0.106:8000/api/';
 //const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';
const endpoint =environment.adminBdlawservice;
//const endpointOther = "https://reactnative.bdlawservice.com/public/api/";
//const reactStartpoint = "https://reactnative.bdlawservice.com/public/api/";
const endpointOther = environment.reactnativeBdlawservice;
const reactStartpoint =environment.reactnativeBdlawservice;

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};






@Injectable({
  providedIn: "root",
})
export class LawyerinfoService {


  private stateSubject = new BehaviorSubject<any>({
    id: 0,                      
    lawyer_id: "",              
    lawyer_group: "",           
    lawyer_name: "",            
    mobile: "",                 
    internet_service: "0",      
    address: "",                
    phone: "",                  
    note: "",                   
    l_type: "",                 
    location: "",               
    ad_user: "0",               
    created_at: "",             
    updated_at: "",          
  });
  state$ = this.stateSubject.asObservable();
  constructor(private http: HttpClient) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  setState(newState: any): void {
    this.stateSubject.next(newState);
  }

  logoutinfo(postData): Observable<any> {
    // alert(postData.logInfoId)
     return this.http
       .post(reactStartpoint + "appsAndWebLogOut?logInfoId="+postData.logInfoId, httpOptions)
       .pipe(map(this.extractData)); 
 
   }

  blrlogin(postData): Observable<any> {
    return this.http
      .post(endpointOther + "appsAndWebLogin", postData, httpOptions)
      .pipe(map(this.extractData));
  }
  bdjclogin(postData): Observable<any> {
    return this.http
      .post(endpointOther + "appsAndWebLogin", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  lcmslogin(postData): Observable<any> {
    return this.http
      .post(endpoint + "lawyerinfo", postData, httpOptions)
      .pipe(map(this.extractData));
  }
  getNewcaseADinfo(postData): Observable<any> {
    return this.http
      .get(reactStartpoint + "getNewCaseRequest?l_id="+postData+"&division=1", httpOptions)
      .pipe(map(this.extractData));
  }
  getNewcaseHDinfo(postData): Observable<any> {
    return this.http
      .get(reactStartpoint + "getNewCaseRequest?l_id="+postData+"&division=2",  httpOptions)
      .pipe(map(this.extractData));
  }

  getLawyerinfo(postData): Observable<any> {
    return this.http
      .post(endpoint + "lawyerinfo", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  geIpadrress(): Observable<any> {
    return this.http
      .get( "https://jsonip.com/",httpOptions)
      .pipe(map(this.extractData));
  }

  getLawyerGroup(postData): Observable<any> {
    return this.http
      .post(endpoint + "lawyerinfo", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getLawyerInfoSadmin(postData): Observable<any> {
    return this.http
      .post(endpoint + "lawyerinfo", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getJusticewise(postData): Observable<any> {
    return this.http
      .post(endpoint + "justicewise", postData)
      .pipe(map(this.extractData));
  }

  getadvocatealllist(postData): Observable<any> {
    return this.http
      .post(endpoint + "advocatelist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getnotificationlist(postData): Observable<any> {
    return this.http
      .post(endpoint + "notificationlist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  showNotification(postData): Observable<any> {
    return this.http
      .post(endpoint + "notificationModal", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gescrollnews(postData): Observable<any> {
    return this.http
      .post(endpoint + "getscroll_news", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getsearchlistnews(postData): Observable<any> {
    return this.http
      .post(endpoint + "getsearch_list_news", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getSearchListBlockStatus(postData): Observable<any> {
    return this.http
      .post(endpoint + "getSearchListBlockStatus", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gescrollnewsdata(postData): Observable<any> {
    return this.http
      .post(endpoint + "getscroll_news_data", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gebodynews(postData): Observable<any> {
    return this.http
      .post(endpoint + "getbody_news", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gebodynewsdetails(postData): Observable<any> {
    return this.http
      .post(endpoint + "getbody_news_detail", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getorganizationalllist(postData): Observable<any> {
    return this.http
      .post(endpoint + "organizationlist", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  showAlert(postData): Observable<any> {
    return this.http
      .post(endpoint + "advocatemodal", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  showOrgAlert(postData): Observable<any> {
    return this.http
      .post(endpoint + "orgmodal", postData, httpOptions)
      .pipe(map(this.extractData));
  }


  getRemoteAddress(postData): Observable<any> {
    return this.http
      .post(endpoint + "remote_address_save", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gettodayonlinevisitor(postData): Observable<any> {
    return this.http
      .post(endpoint + "online_visitor", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gettodaytotalvisitor(postData): Observable<any> {
    return this.http
      .post(endpoint + "total_visitor", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  gettotalnotification(postData): Observable<any> {
    return this.http
      .post(endpoint + "total_notification", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  getDeviceInformation(postData): Observable<any> {
    return this.http
      .post(endpoint + "add_device_info", postData, httpOptions)
      .pipe(map(this.extractData));
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
