import { Component, OnInit } from '@angular/core';
import { CasetodayService } from '../../../casetoday.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';
import { formatDate } from "@angular/common";
@Component({
  selector: 'app-total-case-list-highcourt',
  templateUrl: './total-case-list-highcourt.component.html',
  styleUrls: ['./total-case-list-highcourt.component.css']
})
export class TotalCaseListHighcourtComponent implements OnInit {
  adminBdlawserviceFile=environment.adminBdlawserviceFile
  totalcaselisthddata:any = [];
  totalcaselisthddatacount:any = [];
  totalcaselisthddetailsdata:any = [];
  totalcaselisttwdata:any = [];
  totalcasesummarylwdata:any = [];
  noResultFound= false
  remarksData:any = [];

  remarks:any;
 
  search_code:any;
  

  errorMessage: string;
  isLoading: boolean = true;

  status:boolean = false;
  details:boolean = false;
  Totaldetails:boolean = false;

  sadmin:boolean = false;
  detailsdata:boolean = false;
  isLoadingImage:boolean = false;
  isLoadingImage2:boolean = false;
  isLoadingImage3:boolean = false;

  current_username: string;
  current_laywerCode: string;
  is_admin: boolean = false;

  current_lawyerType: string;

  editRow: boolean = false;
  autoId:any;

  lawyer_ids:any;
  case_type_ids:any;
  case_nos:any;
  case_years:any;
  totalCasePdfData: any;
  remarkPermission:number = 0
  caseTypeName = null;
  totalCase: any
  constructor(
      public rest:CasetodayService, 
      private route: ActivatedRoute, 
      private router: Router,
      public app:AppComponent,
      public lawyerinfo_api: LawyerinfoService,
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  

  }


  ngOnInit() {

    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin(); 

    //console.log(this.current_laywerCode)

    this.current_lawyerType = this.app.getLawyerType();

    if(!this.is_admin){
      this.search_code = this.current_laywerCode;
      this.getTotalcaselisthighcourt();
      this.getTotalcaselisthighcourtcount();
    } 

    if(this.is_admin){
     // this.getTotalcaselisthighcourt();
      this.getTotalcasesummaryLWSadmin();
    } 
this.getRemark()
  }

  
  getTotalcaselisthighcourt() {
    let postData = {
      search_code: this.search_code,
    }

   // console.log(postData);
   this.isLoadingImage = true

    this.rest
        .getTotalcaselisthighcourt(postData)
        .subscribe(
          totalcaselisthddata => {
                this.totalcaselisthddata = totalcaselisthddata
                this.isLoading = false
                this.status=true;
                this.Totaldetails=false;
                this.details=false;
                this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }


  getRemark() {


    this.lawyerinfo_api.getRemarkPermission({
      lawyer_code: this.search_code
    }).subscribe({
      next: (res) => {
        this.remarkPermission = Number(res.results[0].is_permission)
       
      },
      error(err) {
        console.error('something wrong occurred: ' + err);
      },
    })
  }

  pdfDonwload() {
    // Add content to the PDF
    // console.log(this.totalCasePdfData)
    // return
    const casekey = Object.keys(this.totalCasePdfData)
    .map((caseTypeKey) => {
      // Retrieve the case array for this case type
      const caseArray = this.totalCasePdfData[caseTypeKey];
  
      // Generate rows for each case in the array
      const caseRows = caseArray
        .map((caseItem) => {
          return `
            <tr>
          
              <td>${caseItem.type_name}</td>
              <td>${caseItem.case_no}/${caseItem.case_year}</td>
              <td>${caseItem.pr == 1 ? 'Petitioner' : 'Respondent'}</td>
              <td style="min-width: 100px !important ;">${ formatDate(
                caseItem.entry_date,
                'dd-MM-yyyy',
                'en'
              ) }</td>
               ${
                 this.remarkPermission == 1
                   ? `<td style="max-width: 150px !important ; word-wrap: break-word;">${caseItem.remarks === null ? ' ' : caseItem.remarks}</td>`
                   : ''
               }
            </tr>
          `;
        })
        .join('');
  
      // Wrap the rows for the current case type
      return `
      ${caseRows}
      <tr style="background: #dddddd; font-weight: bold;">
        <td colspan="${this.remarkPermission == 1 ? 5 : 4}"style='color: #000; text-align: left; font-weight: bold'> Total ${caseTypeKey}: ${caseArray.length}</td>
      </tr>
      `;
    })
    .join(''); // Combine all rows into a single string
  
  // Function to split rows into pages
  
  
  
  // Generate the HTML for all pages
  const pageHtml = `
  <html>
    <head>
      <style>
        @page {
          width: 210mm;
          margin: 0.50inc;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          page-break-before: always;
        }
        body {
          font-family: Arial, sans-serif;
        }
        .header,
        .footer {
          text-align: center;
        }
        .pdf-table {
          width: 100%;
          border-collapse: collapse;
 
        }
        .pdf-table th,
        .pdf-table td {
          border: 1px solid #d5d5d5;
          padding: 4px;
          text-align: left;
        }
        .header-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ada5a5;
          padding: 0px 0;
          height: 31px;
        }
        .header-top .general-code {
          margin-left: 0px;
          color: #000;
        }
        .header-top .header-date {
          margin-right: 0px;
          color: #000;
        }
        table tr th {
          padding: 8px 12px;
          border: 1px solid #d5d5d5;
          background-color: #dddddd;
          color: white;
          text-align: left;
          font-size: 18px;
          font-weight: 500;
           color: #000
        }
        table tbody tr td {
          padding: 8px 12px;
          border: 1px solid #ddd;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #000
        }
        // tr:nth-child(even) {
        //   background-color: #dddddd;
        // }
        .pages {
          width: 210mm;
          background: #fff;
        }
        .footer-design {
          display: flex;
          text-align: left;
          justify-content: space-between;
          line-height: 20px;
          margin-top: 15px;
        }
      </style>
    </head>
    <body>
     <div class="pages">
              <div class="header">
                <div class="header-top">
                  <div>
                      <h2 class="general-code">${this.totalcaselisthddata[0].lawyer_group == 1 ? 'General': 'Special'}  Code: ${
                      this.totalcaselisthddata[0].lawyer_id
                    } </h2>
                  </div>
                  <div>
                  <p>(Print Time: ${formatDate(
                      new Date(),
                      'dd-MM-yyyy',
                      'en'
                    )} At ${new Date().toLocaleTimeString()})</p>
                  </div>
                  <div>
                    <h4 class="header-date">Date: ${formatDate(
                      new Date(),
                      'dd-MM-yyyy',
                      'en'
                    )}</h4>
                  </div>
                </div>
               <div style='text-align: left; margin: 15px 0; 0 height : 90px' >
                  <h2 style='margin: 0; padding: 0; font-size: 20px'>${
                    this.totalcaselisthddata[0].lawyer_name
                  }</h2>
                  <h5 style='margin: 0; padding: 8px 0px; font-size: 16px'>${
                    this.totalcaselisthddata[0].address
                  }</h5>
                  <p style='margin: 0; padding: 0'>${
                    this.totalcaselisthddata[0].phone
                  }</p>
                </div>

                

                 <div style='text-align: center; margin: 15px 0 ; background: #dddddd; color: #000 ' >
                  
                  <p style='margin: 0; padding: 10px 0px; font-size: 22px; font-weight: bold;'>   ${this.caseTypeName !== null ?  `   (HD) Total ${this.caseTypeName }   `: 'High Court Division Total Cases' }  :  ${
                    this.totalCase.length
                  }</p>
                </div>
              </div>
              <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                <thead>
                  <tr>
                    <th>Case Type</th>
                    <th>Case No</th>
                    <th>P/R</th>
                    <th style="min-width: 100px !important ;">Entry Date</th>
                    ${
                      this.remarkPermission == 1 ? ' <th style="max-width: 150px !important">Remarks</th>' : ''
                    }
                  </tr>
                </thead>
                <tbody>
                  ${casekey}
                </tbody>
              </table>
              <div style='text-align: center; margin: 15px 0 ; background: #dddddd; color: #000 ' >
                  
                  <p style='margin: 0; padding: 10px 0px; font-size: 22px; font-weight: bold;'>   ${this.caseTypeName !== null ?  `(HD) Total   ${this.caseTypeName } `: 'High Court Division Total Cases' }  :  ${
                    this.totalCase.length
                  }</p>
                </div>
              <div class="footer">
                <div class="footer-design">
                  <div style="padding-right: 32px;">
                    <p style="font-size: 14px">Service Provide by : <span style="font-size: 14px; font-weight: bold; padding-right: 4;">SIDDIQUE ENTERPRISE </span> Room No. 45 and 60 (G.F), Phone: 01819-49 09 29, 01772-72 78 78
                 <br/>   E-mail : bdlawservice@gmail.com, Web: <b>www.bdlawservice.com & www.bdcourt.com</b> </p>
                  </div>
              
                </div>
              </div>
            </div>
    </body>
  </html>
  `;

const iframe = document.createElement('iframe');
iframe.style.position = 'absolute';
iframe.style.top = '-9999px';
document.body.appendChild(iframe);

const doc = iframe.contentWindow.document || iframe.contentDocument;
if (!doc) {
  console.error('Failed to access iframe document.');
  return;
}

doc.open();
doc.write(pageHtml)
doc.close()

iframe.onload = () => {
  iframe.contentWindow.focus();
  iframe.contentWindow.print();
};


  }



  getTotalcaselisthighcourtcount() {
    let postData = {
      search_code: this.search_code,
    }
 
   // console.log(postData);

    this.rest
        .getTotalcaselisthighcourtcount(postData)
        .subscribe(
          totalcaselisthddatacount => {

            if (totalcaselisthddatacount> 0) {
              this.totalcaselisthddatacount = totalcaselisthddatacount
              this.noResultFound = false
            }else{
              this.noResultFound = true
              this.totalcaselisthddatacount = []
            }
              
                this.isLoading = false
                this.status=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }
  
  
  getTotalcaselisthighcourtdetails(l_id,case_type_id, type_name) {
    //console.log(l_id);
    //console.log(case_type_id);

    //window.scroll(0,520);
  

    window.scrollTo({
      top: document.documentElement.scrollHeight - 10,
      left: 0, 
      behavior: 'smooth'
    });

    

    this.autoId =null;
    this.isLoadingImage2 = true
    this.detailsdata = false

    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);

   // this.isLoadingImage = true
   this.caseTypeName = type_name 
   const newArray: { [key: string]: any[] } = {};
    this.rest
        .getTotalcaselisthighcourtdetails(postData)
        .subscribe(
          totalcaselisthddetailsdata => {
                this.totalcaselisthddetailsdata = totalcaselisthddetailsdata
                totalcaselisthddetailsdata.forEach((item: any) => {
                  const key = type_name; 
                  if (!newArray[key]) {
                    newArray[key] = [];
                  }
                  newArray[key].push(item); 
                });
          
                this.totalCasePdfData = newArray
                this.totalCase = totalcaselisthddetailsdata
                this.isLoading = false
                this.details=true;
                this.Totaldetails=false;
                this.isLoadingImage2 = false;
                this.detailsdata = true;
                this.editRow = false;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage2 = false
            }
        );
  }


  getTotalcaselistCountTW(l_id,case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);
    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);

    this.rest
        .getTotalcaselistCountTW(postData)
        .subscribe(
          totalcaselisttwdata => {
                this.totalcaselisttwdata = totalcaselisttwdata
                this.isLoading = false
                this.details=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  getTotalcasesummaryLW(l_id) {


    this.autoId =null;

    //console.log(l_id);
    let postData = {
      l_id: l_id,
    }

    this.isLoadingImage3 = true
    this.detailsdata = false

    window.scrollTo({
      top: document.documentElement.scrollHeight -10,
      left: 0, 
      behavior: 'smooth'
    });
    //console.log(postData);

    this.rest
        .getTotalcasesummaryLW(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
              this.caseTypeName = null
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.totalCasePdfData = totalcasesummarylwdata
                const combinedArray = (Object.values(totalcasesummarylwdata) as any[][]).reduce((accumulator, currentArray) => {
                  return [...accumulator, ...currentArray];
                }, []);
                this.totalCase = combinedArray
                this.isLoading = false
                this.status=true;
                this.Totaldetails=true;
                this.details=false;
                this.isLoadingImage3 = false;
                this.detailsdata = true;
                this.editRow = false;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.status=false;
                this.isLoadingImage3 = false;
            }
        );
  }


  getTotalcasesummaryLWSadmin() {
    let postData = {
      l_id: this.search_code,
    }

    //console.log(this.is_admin);

   // console.log(postData);

    this.rest
        .getTotalcasesummaryLWSadmin(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
              this.totalCasePdfData = totalcasesummarylwdata
          
              const combinedArray = (Object.values(totalcasesummarylwdata) as any[][]).reduce((accumulator, currentArray) => {
                return [...accumulator, ...currentArray];
              }, []);
              this.totalCase = combinedArray
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.isLoading = false
                this.status=true;
                this.sadmin=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  editRemarks(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year, remarks) 
  {

    this.editRow = true;
    this.autoId =autoIds;

    this.lawyer_ids =lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;
    this.remarks = remarks;

  }

  addRemarks(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year) 
  {

    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks:this.remarks,
    }

    //console.log(this.remarks);

    this.rest
        .saveOrEditRemarks(postData)
        .subscribe(
          remarksData => {
                this.remarksData = remarksData
                this.isLoading = false
                this.details=true;
                this.getTotalcaselisthighcourtdetails(lawyer_id,case_type_id, '');
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  addRemarksForDetails(autoIds, lawyer_id, lawyer_auto_id, case_type_id, case_no, case_year) 
  {

    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks:this.remarks,
    }

    //console.log(this.remarks);

    this.rest
        .saveOrEditRemarks(postData)
        .subscribe(
          remarksData => {
                this.remarksData = remarksData
                this.isLoading = false
                this.details=true;
                this.getTotalcasesummaryLW(lawyer_id);
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
