//import { Component } from '@angular/core';
import { Component, OnInit, HostListener, Inject, OnDestroy } from '@angular/core';
//import {formatDate } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from 'src/app/alert/alert.component';
import { AutoLogoutService } from './auto-logout.service';
import { LawyerinfoService } from './lawyerinfo.service';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, ActivatedRoute, Router, Event } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
const serverEnv = environment.server;

//import { Http, Response, Headers, RequestOptions } from '@angular/http';
//import { LawyerinfoService } from '../../../lawyerinfo.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AutoLogoutService]
})
export class AppComponent implements OnInit, OnDestroy {

  remoteAddress: any;
  remoteAddressData: any = [];
  browserInfo: any;
  browser_versionInfo: any;
  deviceInfo: any;
  osInfo: any;
  os_versionInfo: any;
  userAgentInfo: any;
  deviceInfoData: any = [];
  errorMessage: string;

  isLoading: boolean = false;
  // is_menushow: boolean = false;
  is_menushow = false;


  is_loggedout: boolean = true;
  isLogin: boolean = true;
  isLogout: boolean = true;

  public dateValue: Date = new Date();
  title = 'Siddique Enterprise';
  is_loggedin: boolean = false;
  is_admin: number = 0;
  is_sAdmin: boolean = false
  scrollnews: any = [];

  getCurrentYear: any;

  isSAdminLoggedIn: any;
  current_LogInfoId: string;
  //deviceInfo = null;
  serverEnv = serverEnv
  userInfo = this.rest.userInfo$;
  access_token: string;
  log_info_id: string;
  loggedin_ad_user: any;
  userInfoData: any;
  private subscription: Subscription = new Subscription();
  constructor(
    private router: Router,
    private http: HttpClient,
    private SimpleModalService: SimpleModalService,
    private autoLogoutService: AutoLogoutService,
    public lawyerinfo_api: LawyerinfoService,
    private deviceService: DeviceDetectorService,
    public rest: JwtService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        //console.log('the data', data);
        this.remoteAddress = data;
        this.getRemoteAddress();
      })


    // if (this.loggedIn()) {
    //   router.navigate(['home']);
    // }
    // else{
    //   router.navigate(['']);
    // }
    //this.getDeviceInformation();
  }

  /*
    epicFunction() {
      //console.log('hello Home component');
      this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      console.log(this.deviceInfo);
      console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      console.log(isTablet);  // returns if the device us a tablet (iPad etc)
      console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    }*/



  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.is_menushow =
          this.activatedRoute.firstChild.snapshot.data.is_menushow !== false;
        // if (!this.loggedIn()) {
        // console.log(this.router.url);
        // console.log("robeen"+this.router.url);
        //   this.router.navigate(['']);
        // }
      }
      // console.log("robeen"+this.router.url);
    });
    // this.getDeviceInformation();

    this.gescrollnews();
    var searchByDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    localStorage.setItem("searchByDate", JSON.stringify(searchByDate));
    let SAdminLoggedIn = this.isSuperAdminLoggedIn();

    if (SAdminLoggedIn) {
      alert(atob(SAdminLoggedIn))
      this.isSAdminLoggedIn = atob(SAdminLoggedIn);
    }

    // console.log(SAdminLoggedIn)
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i); // Get the key at index i
      const value = localStorage.getItem(key); // Get the value associated with the key
      console.log(`Key: ${key}, Value: ${value}`);
    }
    const checkAdmin = localStorage.getItem('loggedin_username')
    const loggedin_ad_user = localStorage.getItem('loggedin_ad_user')

    if ((checkAdmin && checkAdmin == 'sadmin') && (loggedin_ad_user && +loggedin_ad_user == 1)) {
      this.is_admin = 1
    } else {
      if (this.isAdminForMenu()) {
        this.is_admin = 1;
      } else {
        this.is_admin = 0;
      }
    }

  }

  ngOnInit() {


    const userId = (localStorage.getItem('is-sdmin'))
    const userToken = (localStorage.getItem('access_token'))
    const logInfoId = (localStorage.getItem('log_info_id'))
    const loggedInAdUser = (localStorage.getItem('loggedin_ad_user'))
    console.log(userId);
    // console.log(typeof(loggedInAdUser), 'loggedInAdUser',loggedInAdUser )
    if (!this.is_sAdmin) {
      if (+userId === 100 || Number(userId) === 105) {
        this.is_sAdmin = true;
      } else {
        this.is_sAdmin = false;
      }
    }

    if (userToken == null && loggedInAdUser == null) {
      this.subscription = this.rest.userInfo$.subscribe(res => {
        // console.log(res);

        this.userInfo = res;
        this.userInfoData = res;
        this.access_token = res.access_token;
        this.log_info_id = res.log_info_id;
        this.loggedin_ad_user = res.loggedin_ad_user;
        if (res.access_token) {
          this.is_loggedin = true;
          localStorage.setItem('lastAction', Date.now().toString());
        }
        if(res.loggedin_ad_user !== 0 && res.loggedin_username == 'sadmin'){
          this.is_admin = 1
        }else{
          this.is_admin = 0
        }
      });
    } else {
      this.access_token = userToken
      this.log_info_id = logInfoId;
      if (userToken) {
        this.is_loggedin = true;
        localStorage.setItem('lastAction', Date.now().toString());
      }
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.is_menushow =
          this.activatedRoute.firstChild.snapshot.data.is_menushow !== false;
        // if (!this.loggedIn()) {
        // console.log(this.router.url);
        // console.log("robeen"+this.router.url);
        //   this.router.navigate(['']);
        // }
      }
      // console.log("robeen"+this.router.url);
    });
    this.current_LogInfoId = this.getLogInfoId();

    // this.setUpAnalytics();

    this.gescrollnews();



    // if (this.loggedIn()) {
    //   this.is_loggedin = true;

    //   localStorage.setItem('lastAction', Date.now().toString());

    // } else {
    //   this.is_loggedin = false;
    // }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to top when the route changes
        window.scrollTo(0, 0);
      }
    });

  }

  ngOnDestroy(): void {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }

  }
  //   setUpAnalytics() {
  //     this.router.events.pipe(filter(event => event instanceof NavigationEnd))
  //         .subscribe((event: NavigationEnd) => {
  //             gtag('config', 'G-YOUR-GOOGLE-ID',
  //                 {
  //                     page_path: event.urlAfterRedirects
  //                 }
  //             );
  //         });
  // }
  gescrollnewsdata(id) {
    //console.log(id);
  }

  getRemoteAddress() {
    // console.log(this.remoteAddress.ip);
    let postData = {
      //AllremoteAddress: this.remoteAddress,
      remoteAddress: this.remoteAddress.ip,

      //AllremoteAddress: HttpContext.Current.Request.ServerVariables["REMOTE_ADDR"],
    }

    this.lawyerinfo_api
      .getRemoteAddress(postData)
      .subscribe(
        remoteAddressData => {
          this.remoteAddressData = remoteAddressData
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  actionLogout() {

    this.isLoading = true;
    let postData = {
      logInfoId: this.current_LogInfoId,
    }

    sessionStorage.removeItem('lawyerInfo')
    //alert('l')
    // this.rest.logout(postData);
    this.lawyerinfo_api
      .logoutinfo(postData)
      .subscribe(
        lawyerinfodata => {
          if (lawyerinfodata.resp == 1) {
            localStorage.clear();
            this.isLogin = true
            this.isLogout = false
            this.is_loggedin = false;
            this.is_loggedout = true;
            this.isLoading = false;
            // this.router.navigate(['Homecontent']);
            //this.router.navigateByUrl('/');
            //  window.opener.reload();
            window.self.close();
            if (window.opener != null && !window.opener.closed) {
              window.opener.location.reload();
            }

          }
        }
      );
    // this.rest.logout();


    //setTimeout (window.close, 20);
    // window.opener.location.reload();
    // window.self.close();
    // 
    //location.reload();

  }

  getDeviceInformation() {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    //const isMobile = this.deviceService.isMobile();
    //const isTablet = this.deviceService.isTablet();
    //const isDesktopDevice = this.deviceService.isDesktop();
    //console.log(this.deviceInfo);
    //console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    //console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.

    let postData = {
      browserInfo: this.deviceInfo.browser,
      browser_versionInfo: this.deviceInfo.browser_version,
      deviceInfo: this.deviceInfo.device,
      osInfo: this.deviceInfo.os,
      os_versionInfo: this.deviceInfo.os_version,
      userAgentInfo: this.deviceInfo.userAgent,
    }

    this.lawyerinfo_api
      .getDeviceInformation(postData)
      .subscribe(
        deviceInfoData => {
          this.deviceInfoData = deviceInfoData
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }


  gescrollnews() {
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
      .gescrollnews(postData)
      .subscribe(
        scrollnews => {
          // console.log(courtwisedata);
          this.scrollnews = scrollnews
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 150) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }
  getComming(): boolean {
    return localStorage.getItem('comming_soon') !== null;
    //return localStorage.getItem('access_token') !== null;
  }
  getLogInfoId(): string {
    // return localStorage.getItem('log_info_id');
    const logInfoId = this.log_info_id || localStorage.getItem('log_info_id');
    return logInfoId;
  }
  getUserName(): string {
    return localStorage.getItem('loggedin_username');
  }

  getLawyerCode(): string {
    return localStorage.getItem('loggedin_lawyercode');
  }

  getLawyerType(): string {
    return localStorage.getItem('loggedin_lawyerType');
  }

  isAdmin(): boolean {
    return localStorage.getItem('loggedin_ad_user') !== "0";
  }

  isAdminForMenu(): boolean {
    // return localStorage.getItem('loggedin_ad_user') !== "0";

    // this.rest.userInfo$.subscribe(res => {
    //   if (res.loggedin_ad_user !== '0') {
    //     return false
    //   } else {
    //     if (localStorage.getItem('loggedin_ad_user') !== "0") {
    //       return false
    //     } else {
    //       return true
    //     }
    //   }
    // })
    let isAdminCheck = false;

    this.rest.userInfo$.subscribe({
      next: (res) => {
        // console.log(res)
        
        isAdminCheck = res.loggedin_ad_user !== '0' && res.loggedin_username === 'sadmin';
      },
      error: (err) => {
        console.error('Error fetching user info:', err);
      },
    });
    return  isAdminCheck;
  }

  loggedIn(): boolean {
    // try {
    //   const userAccess = this.access_token;
    //   const userAccessLocal = localStorage.getItem('access_token');

    //   return Promise.resolve(!!userAccess || !!userAccessLocal);
    // } catch {
    //   return Promise.resolve(false);
    // }
    return localStorage.getItem('access_token') != null
  }


  isSuperAdminLoggedIn(): string {
    return localStorage.getItem('issa');
  }

  showAlert() {
    this.SimpleModalService.addModal(AlertComponent, { title: 'Please login to activate this menu.', message: 'If you are not a registered user, Please contact with SIDDIQUE ENTERPRISE \n\r Contact #   018   19   49   09   29' });
  }
}