import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JwtService } from '../../jwt.service';
import { AppComponent } from '../../app.component';



import { isNull } from '@angular/compiler/src/output/output_ast';
import { environment } from 'src/environments/environment';

const endpoint = environment.adminBdlawservice;

@Component({
  selector: 'app-homecontent',
  templateUrl: './homecontent.component.html',
  styleUrls: ['./homecontent.component.css']
})
export class HomecontentComponent implements OnInit {

  scrollnews: any = [];
  scrollnewsdetails: any = [];
  errorMessage: string;
  errorMessagelcms: string;
  isLoadingImage: boolean = false;
  userdata: any = [];
  lcmsuserdata: any = [];
  lawyerinfodata: any = [];
  username: any;
  password: any;
  lawyerCode: any;
  mobile: any;

  isLoading: boolean = false;
  isLoadinglcms: boolean = false;
  is_loggedin: boolean = false;
  is_lcms_loggedin: boolean = false;
  is_loggedout: boolean = true;
  isLogin: boolean = false;
  isLogout: boolean = true;
  current_laywerCode: string;
  blrUrl: string;
  someSubscription: any;
  totalnotification: any = [];
  baseUrl: string;
  rememberMe: boolean = false;
  constructor(
    public lawyerinfo_api: LawyerinfoService,
    private route: ActivatedRoute,
    private router: Router,
    public rest: JwtService,
    public app: AppComponent,


  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.someSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Here is the dashing line comes in the picture.
        // You need to tell the router that, you didn't visit or load the page previously, so mark the navigated flag to false as below.
        this.router.navigated = false;
      }
    });


  }

  ngAfterViewInit() {

    this.current_laywerCode = this.app.getLawyerCode();

    if (this.current_laywerCode != null && this.current_laywerCode != "sadmin") {
      // this.getLawyerInfo(this.current_laywerCode);
      this.gettotalnotification();
    }


  }

  ngOnInit() {
    this.gebodynews();
    this.gebodynewsdetails();
    this.baseUrl = window.location.origin;

    if (this.app.loggedIn()) {
      this.is_loggedin = true;
    } else {
      this.is_loggedin = false;

    }
    // setTimeout(()=> this.reloadCurrentRoute(), 100);


    let urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    //urlParams = atob(urlParams); 
    let token = urlParams.get('token');
    let ss = atob(token);
    let alld1 = ss.split('&pass=');

    let userId = alld1[0];
    let Passtoken = alld1[1];
   

    if (userId != null) {
      this.username = userId;
    }
    if (Passtoken != null) {
      this.password = Passtoken;
    }
    if (Passtoken != null && userId != null) {
      this.actionAutoLogin();
    }
  }


  actionAutoLogin() {
    let postData = {
      username: this.username,
      password: this.password,
      apps_user_type: 1,
      is_mobile: 2
    }

    localStorage.setItem('is-sdmin', this.username)
    if (+this.username === 100 || +this.username === 105) {
      this.app.is_sAdmin = true;
    }else{
      this.app.is_sAdmin = false;
    }
    this.isLoading = true;
    this.rest
      .bdLawlogin(postData)
      .subscribe(
        userdata => {
          this.getLawyerInfo(userdata.user_info.l_id)
          console.log(userdata, 'user data');
          this.userdata = userdata;
          this.lawyerinfodata = '';
          this.isLogin = false;
          this.isLogout = true;
          this.is_loggedin = true;
          this.is_loggedout = false;
          this.isLoading = false;
          this.errorMessage = '';

          if (this.rememberMe) {
            localStorage.setItem('rememberMe', 'yes');
            localStorage.setItem('rememberMeuser', this.username);
            localStorage.setItem('rememberMepass', this.password);

          }
          else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("rememberMeuser");
            localStorage.removeItem("rememberMepass");
            this.username = '';
            this.password = '';
          }
        },
        error => {
          this.errorMessage = <any>error
          this.isLogin = true
          this.isLogout = false
          this.is_loggedin = false;
          this.is_loggedout = true;
          this.isLoading = false;
          this.username = '';
          this.password = '';
          window.self.close();
          if (window.opener != null && !window.opener.closed) {
            window.opener.location.reload();
          }
        }
      );
  }
  ngOnDestroy() {
    if (this.someSubscription) {
      this.someSubscription.unsubscribe();
    }
  }
  //refresh current page without reloading
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  //newcode
  actionLogin() {
    let postData = {
      username: this.username,
      name: this.password
    }
    //console.log(postData);

    this.isLoading = true;

    this.rest
      .login(this.username, this.password)
      .subscribe(
        userdata => {
          console.log(userdata);
          this.userdata = userdata;
          this.lawyerinfodata = '';
          this.isLogin = false;
          this.isLogout = true;
          this.is_loggedin = true;
          this.is_loggedout = false;
          this.isLoading = false;
          this.errorMessage = '';

          this.username = '';
          this.password = '';

          this.router.navigateByUrl('/home');
          // location.reload();

        },
        error => {
          this.errorMessage = <any>error
          this.isLogin = true
          this.isLogout = false
          this.is_loggedin = false;
          this.is_loggedout = true;
          this.isLoading = false;

        }
      );



  }

  actionlcmsLogin() {
    let postData = {
      lawyerCode: this.lawyerCode

    }
    //console.log(postData);

    this.isLoadinglcms = true;
    //window.location.href='https://lcmsbd.com/'; 
    this.lawyerinfo_api
      .lcmslogin(postData)
      .subscribe(
        lcmsuserdata => {
          // console.log(lcmsuserdata);
          this.lcmsuserdata = lcmsuserdata;
          //alert(Number(this.mobile));
          this.isLoadinglcms = false;
          if (this.lawyerCode === lcmsuserdata.lawyer_id && Number(this.mobile) === Number(lcmsuserdata.mobile)) {
            this.is_lcms_loggedin = true;
            window.location.href = 'https://new.bdlawreference.com/Common/dokarindexlawbddibinak'

            this.errorMessagelcms = '';
          }
          else {
            this.errorMessagelcms = "Search List Code No Or Search List Mobile No not Match";
          }
          // this.is_lcms_loggedin = true;

          // location.reload(); 

        },
        error => {
          this.errorMessagelcms = <any>error
          //  window.location.href='https://lcmsbd.com/';
          this.isLoadinglcms = false;
        }
      );



  }




  actionLogout() {

    this.isLoading = true;

    this.rest.logout();

    this.isLogin = true
    this.isLogout = false
    this.is_loggedin = false;
    this.is_loggedout = true;
    this.isLoading = false;
    this.router.navigateByUrl('/');
    // location.reload();

  }

  getLawyerInfo(lawyerCode) {

    let postData = {
      lawyerCode: lawyerCode
    }

    this.lawyerinfo_api
      .getLawyerinfo(postData)
      .subscribe(
        lawyerinfodata => {
          this.lawyerinfodata = lawyerinfodata
          this.lawyerinfo_api.setState(lawyerinfodata)
          if (this.lawyerinfo_api.state$) {
            sessionStorage.setItem('lawyerInfo', JSON.stringify(lawyerinfodata))
            this.router.navigate(['/home']).then(() => {
              // window.location.reload();
            });
          }
        }
      );
  }


  gettotalnotification() {
    let postData = {
      current_laywerCode: this.current_laywerCode,
    }
    //console.log(this.current_laywerCode);

    this.lawyerinfo_api
      .gettotalnotification(postData)
      .subscribe(
        totalnotification => {
          this.totalnotification = totalnotification
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  /////////////
  gebodynews() {

    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
      .gebodynews(postData)
      .subscribe(
        scrollnews => {
          // console.log(courtwisedata);
          this.scrollnews = scrollnews
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  gebodynewsdetails() {
    this.isLoadingImage = true;
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
      .gebodynewsdetails(postData)
      .subscribe(
        scrollnewsdetails => {
          // console.log(courtwisedata);
          this.scrollnewsdetails = scrollnewsdetails
          this.isLoadingImage = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoadingImage = false
        }
      );
  }

}
