import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
//import { tap,map } from "rxjs/operators";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

//const endpoint = 'http://localhost:8888/bdlawservice_admin/public/api/';

//const endpointOther = "https://reactnative.bdlawservice.com/public/api/";
//siddiqueenterprise.com
const endpoint = environment.adminBdlawservice;
const endpointOther = environment.reactnativeBdlawservice;

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};


@Injectable({
  providedIn: "root",
})
export class JwtService {
  private stateSubject = new BehaviorSubject<any>({
    access_token: "",         
    loggedin_name: "",         
    loggedin_username: "",         
    loggedin_lawyercode: "",         
    loggedin_ad_user: "",         
    loggedin_lawyerType: "", 
    log_info_id: "" ,
    issa: ""     
  });
  userInfo$ = this.stateSubject.asObservable();
  private blrSubject = new BehaviorSubject<any>({
    blr_message: "",
    blr_code: "",
    blr_is_access: ""
  });
  blrInfo$ = this.blrSubject.asObservable();
  private bdjcSubject = new BehaviorSubject<any>({
    blr_message: "",
    blr_code: "",
    blr_is_access: ""
  });
  bdjcInfo$ = this.blrSubject.asObservable();

  constructor(private httpClient: HttpClient) {}
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  login(username: string, password: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpoint + "login", {
        username,
        password,
      })
      .pipe(
        tap((res) => {

         // console.log(res);

         const data  = {
          access_token: res.api_token,
          loggedin_name: res.name,
          loggedin_username: res.username,
          loggedin_lawyercode: res.l_id,
          loggedin_ad_user: res.ad_user,
          loggedin_lawyerType: res.l_type,
          log_info_id: "",
          issa: ""
         }
         this.stateSubject.next(data);
         console.log('jwt res', data)
          localStorage.setItem("access_token", res.api_token);
          localStorage.setItem("loggedin_name", res.name);
          localStorage.setItem("loggedin_username", res.username);
          localStorage.setItem("loggedin_lawyercode", res.l_id);
          localStorage.setItem("loggedin_ad_user", res.ad_user);
          localStorage.setItem("loggedin_lawyerType", res.l_type);


          if(res.username=='sadmin')
          {
            localStorage.setItem("issa", btoa("sadminLoggedIn"));
          }
        })
      );
  }

  bdLawlogin(postData): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin",  postData, httpOptions)
      .pipe(
        tap((res) => {

         console.log('token ', res);
         const data  = {
          access_token: res.users,
          log_info_id: res.logId,
          loggedin_name: res.user_info.l_id == 100? 'super' :   res.user_info.name,
          loggedin_username: res.user_info.l_id == 100? 'sadmin' : res.user_info.l_id,
          loggedin_lawyercode: res.user_info.l_id == 100? 'sadmin' : res.user_info.l_id,
          loggedin_ad_user: res.user_info.l_id == 100? "1":"0" ,
          loggedin_lawyerType: res.l_type,
          issa: ""
         }
         this.stateSubject.next(data);
         console.log('bdLawlogin', data)

         localStorage.setItem("access_token", res.users);
         localStorage.setItem("log_info_id", res.logId);
         localStorage.setItem("loggedin_name", res.user_info.l_id == 100? 'super' :   res.user_info.name);
          localStorage.setItem("loggedin_username", res.user_info.l_id == 100? 'sadmin' : res.user_info.l_id);
          localStorage.setItem("loggedin_lawyercode", res.user_info.l_id == 100? 'sadmin' : res.user_info.l_id );
          localStorage.setItem("loggedin_ad_user", res.user_info.l_id == 100? "1":"0" );
         localStorage.setItem("loggedin_lawyerType",  res.l_type);
          if(res.user_info.l_id === 100)
          {
            localStorage.setItem("issa", btoa("sadminLoggedIn"));
            data.issa = res.user_info.l_id;
          }
        })
      );
  }
  bdLawLoginlogin(postData): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin",  postData, httpOptions);
  }


  blrlogin(lawyerCode: string, mobile: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin", {
        username:lawyerCode,
        password:mobile,
      })
      .pipe(
        tap((res) => {

         // console.log(res);
         localStorage.setItem("blr_message", res.message);
         localStorage.setItem("blr_code", res.code);
         localStorage.setItem("blr_is_access", res.is_access); 

         const data ={
          blr_message: res.message,
          blr_code: res.code,
          blr_is_access: res.is_access
         }
         this.blrSubject.next(data)
        })
      );
  }

  bdjclogin(username: string, password: string): Observable<any> {
    return this.httpClient
      .post<{ access_token: string }>(endpointOther + "appsAndWebLogin", {
        username,
        password,
      })
      .pipe(
        tap((res) => {
         // console.log(res);
        const data  ={
          bdjc_code: res.message,
          bdjc_message: res.logId,
          bdjc_is_access: res.code
        }
         this.blrSubject.next(data)      
          localStorage.setItem("bdjc_message", res.message);
          localStorage.setItem("bdjc_code", res.code);
          localStorage.setItem("bdjc_is_access", res.is_access);  
          
        })
      );
  }



  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("loggedin_name");
    localStorage.removeItem("loggedin_username");
    localStorage.removeItem("loggedin_lawyercode");
    localStorage.removeItem("loggedin_ad_user");
    localStorage.removeItem("loggedin_lawyerType");
    localStorage.removeItem("issa");
   // localStorage.clear();
  }
}
